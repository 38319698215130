import {gql} from '@apollo/client'

// GraphQL queries used in the My Stocks app

export const MY_STOCKS_HOLDINGS_QUERY = gql`
  query MyStocksHoldings(
    $portfolioUuids: [String!]
    $pageSize: Int
    $page: Int
  ) {
    myStocksHoldings(
      portfolioUuids: $portfolioUuids
      pageSize: $pageSize
      page: $page
    ) {
      balance
      cash
      cost
      profitAndLoss
      todayGainOrLoss
      todayPercentGainOrLoss
      holdings {
        id
        instrumentId
        name
        symbol
        percentOfPortfolio
        percentGainOrLoss
        shares
        cost
        value
        closingPrice
        costPerShare
        currencyCode
        gainOrLoss
        isCash
        lastCloseValue
        todayGainOrLoss
        todayPercentGainOrLoss
        portfolios {
          uuid
          type
          name
          notional
          default
        }
        instrument {
          exchange
          sector
          accessibleFoolRecommendations {
            action
            portfolio {
              displayName
              product
            }
          }
          quantScore {
            value
          }
          predictionByInstrument {
            returnEstimateHigh
            returnEstimateLow
            maxDrawdownRiskPerYear
            cmaTagLabel
          }
          quote {
            revenueGrowth
            marketCap {
              amount
            }
            beta5y
            fiftyTwoWeekRange {
              min {
                amount
              }
            }
            currentPrice {
              amount
            }
            priceChange {
              amount
            }
            percentChange
            grossMargin
            dividendYield
            peRatio
          }
        }
      }
    }
  }
`

// Query for the My Stocks Portfolio in the Homepage
export const MY_STOCKS_PORTFOLIOS_HOMEPAGE_QUERY = gql`
  query MyStocksWatchlistQuadrant(
    $portfolioUuid: String
    $offset: Int
    $limit: Int
    $limitQuote: Int
    $offsetQuote: Int
  ) {
    myStocksWatchlist(
      portfolioUuid: $portfolioUuid
      offset: $offset
      limit: $limit
      limitQuote: $limitQuote
      offsetQuote: $offsetQuote
    ) {
      id
      name
      symbol
      instrumentId
      portfolios {
        uuid
        name
      }
      instrument {
        quote {
          exchange
          currentPrice
          priceChange
          percentChange
          marketDataProvider
          isRealTime
          currencyCode
        }
      }
    }
  }
`

export const MY_STOCKS_WATCHLIST_QUERY = gql`
  query MyStocksWatchlist($portfolioUuid: String, $offset: Int, $limit: Int) {
    myStocksWatchlist(
      portfolioUuid: $portfolioUuid
      offset: $offset
      limit: $limit
    ) {
      id
      instrumentId
      name
      symbol
      portfolios {
        type
        uuid
        name
        notional
        default
      }
    }
  }
`

export const MY_STOCKS_WATCHLIST_INSTRUMENT_IDS = gql`
  query WatchlistInstrumentIds($instrumentIds: [Int!]!) {
    user {
      watchedInstrumentIds(instrumentIds: $instrumentIds) {
        instrumentId
        isWatched
      }
    }
  }
`
