import type {ApolloClient} from '@apollo/client'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {
  validateHoldings,
  updateHoldings,
  updateWatchlistHoldings,
  updateActivePortfolioSummary,
  setInitialAccountsLoad,
  setInitialTransactionsLoad,
  updatePortfolios,
  updateTransactionsLookup,
  updateActivePortfolioUuid,
  updateAllMissingTransactions,
  updateMissingPortfolioTransactions,
  updatePortfolioTotalData,
  validateWatchlistHoldings,
  invalidateBothHoldings,
  updateSubnavPortfolios,
  updateFetchMoreTransactions,
} from '~app/(main)/my-stocks/my-stocks.slice'
import {
  MY_STOCKS_REDUCER_KEY,
  ALTERNATE_ACTIVE_PORTFOLIO_VIEW,
  MY_STOCKS_USER_PREF_TABLE_KEY,
} from '~data/constants'
import {RootState, AppDispatch} from '~data/client/store'
import {
  getMyStocksPortfoliosApi,
  createMyStocksAccountApi,
  updateMyStocksAccountApi,
  deleteMyStocksManualAccountApi,
  uploadMyStocksCSVApi,
  getMyStocksTransactionsApi,
  getMyStocksPlaceholderHoldingsApi,
  deleteMyStocksInstitutionAccountApi,
  getMyStocksAccountsApi,
} from './index'
import {
  transformHoldingsNew,
  transformPortfolios,
  transformTransactions,
  transformMissingTransactions,
  transformPlaidPortfolios,
  transformWatchlistHoldings,
} from './transforms'
import type {
  Holding,
  PortfolioSummary,
  PortfolioUuidType,
  createAccountRESTAPIResponseType,
  updateAccountRESTAPIResponseType,
  uploadCSVRESTAPIResponseType,
  Ticker,
  CreateTransactionPayload,
  GetTransactionsRESTAPIResponseType,
  UpdatedTransaction,
  NewTicker,
  Transaction,
  TransactionSet,
  TransactionType,
  MissingTransactions,
  MissingTransactionPortfolioType,
  Portfolio,
  PortfolioTotalData,
  getPortfolioSummaryRESTAPIResponseType,
  getAccountsRESTAPIResponseType,
  WatchlistHolding,
} from '~types/my-stocks'
import {
  MY_STOCKS_HOLDINGS_QUERY,
  MY_STOCKS_PORTFOLIOS_HOMEPAGE_QUERY,
  MY_STOCKS_WATCHLIST_QUERY,
} from './query'
import {
  ADD_WATCH_MUTATION,
  DELETE_WATCH_MUTATION,
  ADD_WATCHES_MUTATION,
  DELETE_WATCHES_MUTATION,
  UPDATE_TRANSACTION_MUTATION,
  CREATE_TRANSACTION_MUTATION,
  DELETE_TRANSACTION_MUTATION,
  ADD_WATCH_AND_TRANSACTION_MUTATION,
} from './mutations'
import {MyStocksHoldingsQuery} from '~types/__generated__/graphql'
import {sanitizeProperties} from '~data/api/utils'
import {Session} from 'next-auth/types'
import {setWatches} from '~data/client/watches.slice'
import {cleanUpLocalStorage} from '~data/api/user-preference'
import * as Sentry from '@sentry/react'

// Toggle watch status for a stock
export const toggleWatch = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/toggleWatch`,
  async (
    {
      instrumentId,
      isAdd,
      portfolioUuid,
    }: {
      instrumentId: number
      isAdd: boolean
      portfolioUuid?: string
    },
    {rejectWithValue, extra},
  ) => {
    try {
      const {apolloClient} = extra as {apolloClient: ApolloClient<object>}
      const mutationType = isAdd ? ADD_WATCH_MUTATION : DELETE_WATCH_MUTATION
      const response = await apolloClient.mutate({
        mutation: mutationType,
        variables: {
          input: {
            instrumentId: instrumentId,
            portfolioUuid: portfolioUuid,
          },
        },
      })
      if (
        response.data?.addWatch === '200' ||
        response.data?.deleteWatch === '200'
      ) {
        return response.data.toggleWatch
      }

      throw new Error('Failed to toggle watch status')
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const addWatchesAndTransactions = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/addWatchesAndTransactions`,
  async (
    watchesAndTransactions: {
      watches: Array<{
        instrumentId: number
        isAdd: boolean
        portfolioUuid?: string
      }>
      addedTransactions: {
        accountId: number
        transactions: Ticker[]
      }
    },
    {rejectWithValue, extra, getState, dispatch},
  ) => {
    try {
      const activePortfolioUuid = (getState() as RootState).myStocks
        .activePortfolioUuid
      const {apolloClient} = extra as {apolloClient: ApolloClient<object>}
      const addWatches = watchesAndTransactions.watches.filter((w) => w.isAdd)
      const results = []
      // Build the payload for new holdings to watch
      const watchesPayload = addWatches.map(
        ({instrumentId, portfolioUuid}) => ({
          instrumentId,
          portfolioUuid,
        }),
      )
      // Build the payload for included transactions of the new watched holdings
      const transactionsPayload =
        watchesAndTransactions.addedTransactions.transactions.map(
          (stock: Ticker): CreateTransactionPayload => {
            const defaultTransaction = {
              transactionType: 'placeholder',
              shares: 0,
              price: 0,
              transactionDate: new Date().toJSON(),
            }

            let sharesValue = stock.shares
              ? typeof stock.shares === 'string'
                ? +stock.shares
                : stock.shares
              : defaultTransaction.shares
            // Ensures that value is up to 6 digits
            sharesValue = Number(sharesValue.toFixed(6))

            const priceValue = stock.price
              ? typeof stock.price === 'string'
                ? parseInt(stock.price)
                : stock.price
              : defaultTransaction.price

            return {
              accountId: watchesAndTransactions.addedTransactions.accountId,
              instrumentId: stock.instrumentId,
              transactionType:
                stock.transactionType ||
                (defaultTransaction.transactionType as TransactionType),
              shares: sharesValue,
              price: priceValue,
              transactionDate:
                stock.transactionDate || defaultTransaction.transactionDate,
            }
          },
        )

      if (addWatches.length > 0) {
        for (const watch of watchesPayload) {
          const transaction = transactionsPayload.filter(
            (transaction) => transaction.instrumentId === watch.instrumentId,
          )
          const response = await apolloClient.mutate({
            mutation: ADD_WATCH_AND_TRANSACTION_MUTATION,
            variables: {
              watchInput: watch,
              transactionInput: transaction,
            },
          })
          if (response.data?.addWatch === '200') {
            results.push(response.data.toggleWatch)
          }

          if (response.data?.createMyStocksTransaction) {
            const portfolioUuidsToFetch = activePortfolioUuid
              ? [activePortfolioUuid]
              : []

            if (activePortfolioUuid) {
              await dispatch(invalidateBothHoldings([activePortfolioUuid]))
            }

            const {session} = extra as {session: Session | null}
            const accessToken = session?.accessToken

            if (!accessToken)
              throw new Error(
                'Error fetching portfolio accounts: No token provided.',
              )

            dispatch(
              getMyStocksTransactions({
                token: accessToken,
                portfolioUuids: portfolioUuidsToFetch,
              }),
            )
            dispatch(
              getMissingTransactions({
                token: accessToken,
                allPortfolios:
                  (getState() as RootState).myStocks.portfolios || [],
                type: 'single',
              }),
            )
          }
        }
      }
      if (results.length === 0) {
        throw new Error('Failed to toggle watch status')
      }

      return results
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

// Toggle watch status for multiple stocks
export const toggleWatches = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/toggleWatches`,
  async (
    watches: Array<{
      instrumentId: number
      isAdd: boolean
      portfolioUuid?: string
    }>,
    {rejectWithValue, extra},
  ) => {
    try {
      const {apolloClient} = extra as {apolloClient: ApolloClient<object>}

      // Split watches into adds and deletes
      const addWatches = watches.filter((w) => w.isAdd)
      const deleteWatches = watches.filter((w) => !w.isAdd)

      const results = []

      // Handle adds if any exist
      if (addWatches.length > 0) {
        const addResponse = await apolloClient.mutate({
          mutation: ADD_WATCHES_MUTATION,
          variables: {
            input: addWatches.map(({instrumentId, portfolioUuid}) => ({
              instrumentId,
              portfolioUuid,
            })),
          },
        })
        if (addResponse.data?.addWatches === '200') {
          results.push(addResponse.data.toggleWatch)
        }
      }

      // Handle deletes if any exist
      if (deleteWatches.length > 0) {
        const deleteResponse = await apolloClient.mutate({
          mutation: DELETE_WATCHES_MUTATION,
          variables: {
            input: deleteWatches.map(({instrumentId, portfolioUuid}) => ({
              instrumentId,
              portfolioUuid,
            })),
          },
        })
        if (deleteResponse.data?.deleteWatches === '200') {
          results.push(deleteResponse.data.toggleWatch)
        }
      }

      if (results.length === 0) {
        throw new Error('Failed to toggle watch status')
      }

      return results
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

// Get watches
// This is the simplified version of watchlist that is used
// for updating the homepage watchlist
export const fetchAndSetWatches = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/fetchAndSetWatches`,
  async (
    param: {portfolioUuid?: string} | undefined = undefined,
    {dispatch, rejectWithValue, extra},
  ) => {
    try {
      const {apolloClient} = extra as {apolloClient: ApolloClient<object>}
      const response = await apolloClient.query({
        query: MY_STOCKS_WATCHLIST_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          portfolioUuid: param?.portfolioUuid || null,
        },
      })
      const watches = response?.data?.myStocksWatchlist || []
      dispatch(setWatches({watches}))
      return watches
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

// General operation to get holdings for the homepage
// from store or fetch from graphQL
export const getWatchlistHoldings = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/getWatchlistHoldings`,
  async (
    {
      portfolioUuid,
      apolloClient,
      signal,
      offset,
      limit,
      limitQuote,
      offsetQuote,
      invalidateCache,
    }: {
      portfolioUuid?: PortfolioUuidType // TODO: Should this really be optional?
      apolloClient: ApolloClient<object>
      signal?: AbortController['signal']
      offset?: number
      limit?: number
      limitQuote?: number
      offsetQuote?: number
      invalidateCache?: boolean
    },
    {dispatch, getState, rejectWithValue},
  ) => {
    // Clean portfolio Uuids
    // TODO: Should we check for valid portfolioUuid?
    const cleanedPortfolioUuid =
      portfolioUuid || ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks

    try {
      const {myStocks: myStocksState} = getState() as RootState

      // Do not attempt to lookup or fetch holdings since it is invalid
      // See listeners for triggered fetch
      if (myStocksState.invalidWatchlistHoldings.includes(cleanedPortfolioUuid))
        return

      // Check if we already have the watchlist holdings in the store
      const cachedStocks =
        myStocksState.watchlistHoldingsLookup[cleanedPortfolioUuid]

      if (cachedStocks && !invalidateCache) {
        await dispatch(
          updateWatchlistHoldings({watchlistHoldings: cachedStocks}),
        )
      } else {
        // We don't have both the holdings and the summary in the store, so will need to fetch
        await dispatch(
          fetchWatchlistHoldings({
            portfolioUuid: cleanedPortfolioUuid,
            apolloClient,
            signal: signal || null,
            offset,
            limit,
            limitQuote,
            offsetQuote,
          }),
        )
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

// General operation to get holdings from store or fetch from
// graphQL
export const getHoldings = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/getHoldings`,
  async (
    {
      portfolioUuid,
      apolloClient,
    }: {
      portfolioUuid?: PortfolioUuidType // TODO: Should this really be optional?
      apolloClient: ApolloClient<object>
    },
    {dispatch, getState, rejectWithValue},
  ) => {
    // Clean portfolio Uuids
    // TODO: Should we check for valid portfolioUuid?
    const cleanedPortfolioUuid =
      portfolioUuid || ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks

    try {
      const {myStocks: myStocksState} = getState() as RootState

      // Do not attempt to lookup or fetch holdings since it is invalid
      // See listeners for triggered fetch
      if (myStocksState.invalidHoldings.includes(cleanedPortfolioUuid)) return

      // Check if we already have the holdings and summary in the store
      const cachedStocks = myStocksState.holdingsLookup[cleanedPortfolioUuid]
      const cachedSummary =
        myStocksState.portfolioSummaryLookup[cleanedPortfolioUuid]
      if (cachedStocks && cachedSummary) {
        await dispatch(updateHoldings({holdings: cachedStocks}))
        await dispatch(
          updateActivePortfolioSummary({activePortfolioSummary: cachedSummary}),
        )
      } else {
        // We don't have both the holdings and the summary in the store, so will need to fetch
        await dispatch(
          fetchHoldings({portfolioUuid: cleanedPortfolioUuid, apolloClient}),
        )
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Fetches watchlist holdings for the My Stocks in the homepage
 */
export const fetchWatchlistHoldings = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/fetchWatchlistHoldings`,
  async (
    {
      background = false,
      portfolioUuid,
      apolloClient,
      signal,
      offset,
      limit,
      limitQuote,
      offsetQuote,
    }: {
      background?: boolean
      portfolioUuid: PortfolioUuidType
      apolloClient: ApolloClient<object>
      signal?: AbortSignal | null
      offset?: number
      limit?: number
      limitQuote?: number
      offsetQuote?: number
    },
    {dispatch, rejectWithValue},
  ) => {
    try {
      const uuid =
        portfolioUuid === ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks ||
        portfolioUuid === ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllPortfolios
          ? ''
          : portfolioUuid
      const response = await apolloClient.query({
        query: MY_STOCKS_PORTFOLIOS_HOMEPAGE_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          portfolioUuid: uuid,
          offset,
          limit,
          limitQuote,
          offsetQuote,
        },
        context: {
          fetchOptions: {
            signal: signal,
          },
        },
        errorPolicy: 'all',
        // Setting fetchPolicy ensures we always get the latest data
        // when fetchHoldings gets called. This is important for when
        // fetching holdings after a change in an asset.
        // fetchPolicy: 'network-only',
      })
      const transformedHoldings: WatchlistHolding[] =
        transformWatchlistHoldings(response?.data?.myStocksWatchlist)
      if (transformedHoldings) {
        dispatch(validateWatchlistHoldings(portfolioUuid))
        return {
          portfolioUuid,
          activeWatchlistHoldings: background ? null : transformedHoldings,
          watchlistHoldings: transformedHoldings,
        }
      } else {
        return rejectWithValue('Failed to transform holdings')
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

// Graphql query to fetch holdings
export const fetchHoldings = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/fetchHoldings`,
  async (
    {
      portfolioUuid,
      apolloClient,
    }: {
      portfolioUuid: PortfolioUuidType
      apolloClient: ApolloClient<object>
    },
    {dispatch, getState, rejectWithValue},
  ): Promise<{
    portfolioUuid: PortfolioUuidType
    holdings: Holding[]
    activeHoldings: Holding[] | null
    summary: PortfolioSummary
    activeSummary: PortfolioSummary | null
  }> => {
    try {
      const {myStocks: currentMyStocksState} = getState() as RootState
      const uuid =
        portfolioUuid === ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks ||
        portfolioUuid === ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllPortfolios
          ? ''
          : portfolioUuid
      // Function to create a delay
      // const delay = (ms: number) =>
      //   new Promise((resolve) => setTimeout(resolve, ms))

      // const fetchHoldings = async () => {
      //   // Delay execution by 2000 milliseconds (2 seconds)
      //   await delay(2000)

      //   // Execute the query after the delay
      //   const response = await apolloClient.query({
      //     query: MY_STOCKS_HOLDINGS_QUERY,
      //     variables: {
      //       portfolioUuids: [uuid],
      //     },
      //     // Setting fetchPolicy ensures we always get the latest data
      //     // when fetchHoldings gets called. This is important for when
      //     // fetching holdings after a change in an asset.
      //     fetchPolicy: 'network-only',
      //   })

      //   return response
      // }

      // // Call the function
      // fetchHoldings()
      //   .then((response) => {
      //     console.log(response)
      //   })
      //   .catch((error) => {
      //     console.error(error)
      //   })
      const response = await apolloClient.query({
        query: MY_STOCKS_HOLDINGS_QUERY,
        variables: {
          portfolioUuids: [uuid],
        },
        // Setting fetchPolicy ensures we always get the latest data
        // when fetchHoldings gets called. This is important for when
        // fetching holdings after a change in an asset.
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })

      const transformedHoldings = transformHoldingsNew(
        response?.data?.myStocksHoldings?.holdings,
      )

      // Transform summary
      const holdingData: NonNullable<
        NonNullable<MyStocksHoldingsQuery['myStocksHoldings']>
      > = response?.data?.myStocksHoldings
      const transformedSummary = {
        totalProfitLossPercent:
          holdingData?.profitAndLoss && holdingData?.cost
            ? holdingData.profitAndLoss / holdingData.cost
            : 0,
        totalProfitLoss: holdingData?.profitAndLoss,
        dailyChange: holdingData?.todayGainOrLoss,
        dailyPercentChange: holdingData?.todayPercentGainOrLoss,
        cost: holdingData?.cost,
        cash: holdingData?.cash,
        balance: holdingData?.balance,
      }

      if (transformedHoldings) {
        dispatch(validateHoldings(portfolioUuid))
        return {
          portfolioUuid,
          holdings: transformedHoldings,
          // TODO: This is temporary. Should phase out what the active
          // holdings is and just memoize with createSelector
          activeHoldings:
            currentMyStocksState.activePortfolioUuid === portfolioUuid
              ? transformedHoldings
              : null,
          summary: transformedSummary,
          activeSummary:
            currentMyStocksState.activePortfolioUuid === portfolioUuid
              ? transformedSummary
              : null,
        }
      } else {
        throw rejectWithValue('Failed to transform holdings')
      }
    } catch (error) {
      throw rejectWithValue(error as Error)
    }
  },
)

/**
 * These functions call broker sync for data that
 * gets stored into redux
 */
export const getMyStocksPortfolios = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/getMyStocksPortfolios`,
  async (
    {
      token, // TODO: Phase this out since this is now available within the thunk ThunkAPI
      isInitial,
      updateSubnav,
    }: {
      token?: string
      isInitial?: boolean
      updateSubnav?: boolean
    },
    {dispatch, getState, extra},
  ): Promise<boolean> => {
    const {session} = extra as {session: Session | null}
    const accessToken = token || session?.accessToken
    const state = getState() as RootState
    // TODO: Flesh this out
    if (!accessToken)
      throw new Error('Error fetching portfolio accounts: No token provided.')

    const {myStocks: currentMyStocksState} = getState() as RootState

    // Stops any initial calls if it has already been ran
    if (isInitial && currentMyStocksState.initialAccountsLoad) return true
    dispatch(setInitialAccountsLoad())

    try {
      // Get accounts info from broker-sync
      const accountsData: getAccountsRESTAPIResponseType =
        await getMyStocksAccountsApi(accessToken)

      const accountForSubnav = accountsData.portfolios.map((account) => {
        return {
          uuid: account.uuid,
          name: account.name,
          type: account.type,
        }
      })
      // TODO: Review whether the subnavPortfolios is necessary in the store
      // We will be moving queries to graphQL for accounts so that should make this call considerably faster
      // Look into using the `portfolios` object in the store and using a selector to get the items
      if (state.myStocks.subnavPortfolios.length === 0 || updateSubnav) {
        // Set the subnav portfolios items so they load quickly
        dispatch(updateSubnavPortfolios(accountForSubnav))
      }
      // Get summary info from broker-sync -- this has total data for all portfolios as well as percentGainOrLoss and todayPercentGainOrLoss
      const {
        summaryData,
      }: {summaryData: getPortfolioSummaryRESTAPIResponseType} =
        await getMyStocksPortfoliosApi(accessToken)

      const portfolioSummaryDataSanitized = sanitizeProperties(
        summaryData,
      ) as PortfolioTotalData

      const portfolioTotalData = {
        totalTodayGainOrLoss:
          portfolioSummaryDataSanitized?.totalTodayGainOrLoss,
        totalTodayPercentGainOrLoss:
          portfolioSummaryDataSanitized.totalTodayPercentGainOrLoss,
        totalNumSecurities: portfolioSummaryDataSanitized.totalNumSecurities,
        totalGainOrLoss: portfolioSummaryDataSanitized.totalGainOrLoss,
        totalPercentGainOrLoss:
          portfolioSummaryDataSanitized.totalPercentGainOrLoss,
        totalCost: portfolioSummaryDataSanitized.totalCost,
        totalCash: portfolioSummaryDataSanitized.totalCash,
        totalBalance: portfolioSummaryDataSanitized.totalBalance,
      }

      if (accountsData && accountsData.portfolios) {
        // Transform plaid portfolios and merge back with manual portfolios
        const manualPortfolios = accountsData.portfolios.filter(
          (portfolio) => portfolio.type !== 'plaid',
        )
        const plaidPortfolios = transformPlaidPortfolios(accountsData)
        const mergedPortfolios = [...manualPortfolios, ...plaidPortfolios].sort(
          (a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          },
        )

        // Transform all portfolios as needed by the client and store in redux
        const transformedPortfolios = transformPortfolios(
          mergedPortfolios,
          summaryData?.portfolios,
        )
        dispatch(updatePortfolios({portfolios: transformedPortfolios}))
        dispatch(updatePortfolioTotalData({portfolioTotalData}))
      } else {
        // TODO: Capture error
        console.error('There was an error pulling accounts')
      }
    } catch (error) {
      // TODO: handle this better https://fool.atlassian.net/browse/PT-4922
      return false
    }
    return true
  },
)

export const addMyStocksPortfolio = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/addMyStocksPortfolio`,
  async (
    {
      token,
      name,
      isoCurrencyCode = null,
    }: {
      token?: string
      name: string
      isoCurrencyCode?: string | null
    },
    {dispatch, extra, rejectWithValue},
  ) => {
    try {
      const {session} = extra as {session: Session | null}
      const accessToken = token || session?.accessToken

      if (!accessToken)
        throw new Error('Error fetching portfolio accounts: No token provided.')

      const newPortfolioData: createAccountRESTAPIResponseType =
        await createMyStocksAccountApi(accessToken, name, isoCurrencyCode)

      if (!newPortfolioData) {
        throw new Error('Failed to create new portfolio')
      }

      const portfolio = {
        id: newPortfolioData.id,
        uuid: newPortfolioData.uuid,
        name: newPortfolioData.name,
      }
      // Fetch latest portfolios
      dispatch(
        updateActivePortfolioUuid({portfolioUuid: newPortfolioData.uuid}),
      )
      dispatch(getMyStocksPortfolios({token, updateSubnav: true}))

      return portfolio
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  },
)

export const editMyStocksPortfolio = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/editMyStocksPortfolio`,
  async (
    {
      token,
      accountId,
      name,
    }: {
      token?: string
      accountId: number
      name: string
    },
    {dispatch, extra, rejectWithValue},
  ) => {
    try {
      const {session} = extra as {session: Session | null}
      const accessToken = token || session?.accessToken
      if (!accessToken)
        throw new Error('Error fetching portfolio accounts: No token provided.')
      const updatedPortfolioData: updateAccountRESTAPIResponseType =
        await updateMyStocksAccountApi(accessToken, accountId, name)

      if (!updatedPortfolioData) throw new Error('Failed to update portfolio')

      // Fetch latest portfolios
      dispatch(getMyStocksPortfolios({token: accessToken}))
      return true
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  },
)

export const deleteMyStocksPortfolio = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/deleteMyStocksPortfolio`,
  async (
    {
      token,
      accountId,
      accountType,
      apolloClient,
      currentPortfolioUuid,
    }: {
      token?: string
      accountId: string
      accountType: string
      apolloClient: ApolloClient<object>
      currentPortfolioUuid: string | null
    },
    {dispatch, getState, extra},
  ): Promise<boolean> => {
    const {session} = extra as {session: Session | null}
    const accessToken = token || session?.accessToken
    if (!accessToken)
      throw new Error('Error fetching portfolio accounts: No token provided.')
    try {
      let deletedPortfolioData: number | null | undefined = null
      const pageUuid = (getState() as RootState).myStocks.activePortfolioUuid
      const portfolioUuid =
        currentPortfolioUuid === pageUuid ? '' : pageUuid || ''
      if (accountType === 'plaid') {
        deletedPortfolioData = await deleteMyStocksInstitutionAccountApi(
          accessToken,
          accountId,
        )
      } else {
        deletedPortfolioData = await deleteMyStocksManualAccountApi(
          accessToken,
          accountId,
        )
      }
      if (deletedPortfolioData) {
        // Fetch latest portfolios, holdings, and show all stocks after deleting a portfolio
        dispatch(getMyStocksPortfolios({token, updateSubnav: true}))
        dispatch(
          getHoldings({
            portfolioUuid: portfolioUuid,
            apolloClient,
          }),
        )
        dispatch(
          getMyStocksTransactions({
            token,
            portfolioUuids: [portfolioUuid],
          }),
        )

        if (currentPortfolioUuid === pageUuid) {
          dispatch(
            // The route should depend upon the current portfolio
            // If the user is in a portfolio that is being deleted, they should be redirected to all-stocks
            // Otherwise, they should stay in the portfolio from which they are deleting another portfolio
            updateActivePortfolioUuid({
              portfolioUuid: ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks,
            }),
          )
        }

        // Clear out localstorage user pref data for recently deleted portfolio and any
        // other stale portfolio configurations
        const currentPortfolios = (getState() as RootState).myStocks.portfolios
        let keys =
          currentPortfolios?.map((portfolio) => portfolio?.uuid as string) || []
        // Filter out the deleted portfolio
        keys = keys.filter(
          (portfolioUuid: string) => portfolioUuid !== accountId,
        )
        // Preserve the "all-stocks" config
        keys = [...keys, ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks]

        cleanUpLocalStorage(keys, `user-pref.${MY_STOCKS_USER_PREF_TABLE_KEY}`)
      }
    } catch (error) {
      // TODO: handle this better https://fool.atlassian.net/browse/PT-4922
      return false
    }
    return true
  },
)

export const uploadMyStocksCSV = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/uploadMyStocksCSV`,
  async (
    {
      token,
      file,
      portfolioId,
      portfolioUuid,
      apolloClient,
    }: {
      token?: string
      file: File
      portfolioId: number
      portfolioUuid: string
      apolloClient: ApolloClient<object>
    },
    {dispatch, rejectWithValue, extra},
  ): Promise<uploadCSVRESTAPIResponseType> => {
    const {session} = extra as {session: Session | null}
    const accessToken = token || session?.accessToken
    if (!accessToken)
      throw new Error('Error fetching portfolio accounts: No token provided.')
    try {
      const uploadCSVData = await uploadMyStocksCSVApi({
        token: accessToken,
        file,
        // The 'default'institution is the value when using the TMF Template CSV
        // When we start supporting other CSV templates, this will change
        // based on the brokerage
        institution: 'default',
        accountId: portfolioId,
      })

      if (uploadCSVData) {
        dispatch(
          getHoldings({
            portfolioUuid: portfolioUuid.toString(),
            apolloClient,
          }),
        )
        dispatch(
          getMyStocksTransactions({
            token,
            portfolioUuids: [portfolioUuid.toString()],
          }),
        )

        if (
          !(
            Array.isArray(uploadCSVData) ||
            (uploadCSVData.transactions && uploadCSVData.unresolved_symbols)
          )
        ) {
          throw new Error('There was an error uploading CSV')
        }

        return uploadCSVData
      } else {
        throw rejectWithValue('No data returned from CSV upload')
      }
    } catch (error) {
      throw rejectWithValue(
        error instanceof Error
          ? error.message
          : 'An error occurred during CSV upload',
      )
    }
  },
)

export const createTransactions = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/createTransactions`,
  async (
    {
      token,
      accountId,
      transactions,
    }: {
      token?: string
      accountId: number
      transactions: Ticker[]
    },
    {dispatch, getState, extra, rejectWithValue},
  ) => {
    try {
      const {apolloClient} = extra as {apolloClient: ApolloClient<object>}
      const activePortfolioUuid = (getState() as RootState).myStocks
        .activePortfolioUuid
      // Supporting just placeholders for now
      const transactionPayload = transactions.map(
        (stock: Ticker): CreateTransactionPayload => {
          const defaultTransaction = {
            transactionType: 'placeholder',
            shares: 0,
            price: 0,
            transactionDate: new Date().toJSON(),
          }

          let sharesValue = stock.shares
            ? typeof stock.shares === 'string'
              ? +stock.shares
              : stock.shares
            : defaultTransaction.shares
          // Ensures that value is up to 6 digits
          sharesValue = Number(sharesValue.toFixed(6))

          const priceValue = stock.price
            ? typeof stock.price === 'string'
              ? parseInt(stock.price)
              : stock.price
            : defaultTransaction.price

          return {
            accountId: accountId,
            instrumentId: stock.instrumentId,
            transactionType:
              stock.transactionType ||
              (defaultTransaction.transactionType as TransactionType),
            shares: sharesValue,
            price: priceValue,
            transactionDate:
              stock.transactionDate || defaultTransaction.transactionDate,
          }
        },
      )

      const createTransactionsData = await apolloClient.mutate({
        mutation: CREATE_TRANSACTION_MUTATION,
        variables: {input: transactionPayload},
      })

      if (!createTransactionsData) {
        throw new Error('Failed to create transactions')
      }

      const portfolioUuidsToFetch = activePortfolioUuid
        ? [activePortfolioUuid]
        : []

      if (activePortfolioUuid)
        await dispatch(invalidateBothHoldings([activePortfolioUuid]))

      dispatch(
        getMyStocksTransactions({
          token,
          portfolioUuids: portfolioUuidsToFetch,
        }),
      )
      dispatch(
        getMissingTransactions({
          token,
          allPortfolios: (getState() as RootState).myStocks.portfolios || [],
          type: 'single',
        }),
      )
      return true
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  },
)

export const getMyStocksTransactions = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/getMyStocksTransactions`,
  async (
    {
      token, // TODO: Phase this out since this is now available within the thunk ThunkAPI
      portfolioUuids,
      instrumentIds,
      type,
      dateFrom,
      dateTo,
      sort,
      isInitial,
      page,
      loadMoreTransactions,
      reset,
    }: {
      token?: string
      portfolioUuids?: string[]
      instrumentIds?: number[]
      type?: string
      dateFrom?: string
      dateTo?: string
      sort?: string
      isInitial?: boolean
      loadMoreTransactions?: boolean
      page?: number
      reset?: boolean
    },
    {dispatch, getState, extra},
  ): Promise<boolean> => {
    const {session} = extra as {session: Session | null}
    const accessToken = token || (session?.accessToken as string)
    try {
      const currentMyStocksState = (getState() as RootState).myStocks

      // If there are already transactions saved in the redux store, do not fetch again
      const currentPortfolio = currentMyStocksState.activePortfolioUuid
      if (
        currentPortfolio &&
        currentMyStocksState?.transactionsLookup?.[currentPortfolio]
          ?.transactions &&
        !loadMoreTransactions &&
        !reset
      ) {
        return true
      }

      // Stops any initial calls if it has already been ran
      if (isInitial && currentMyStocksState.initialTransactionsLoad) return true
      dispatch(setInitialTransactionsLoad())
      // Remove any "all-stocks" or "all-portfolios"
      const cleanedPortfolioUuids = portfolioUuids?.filter(
        (uuid) =>
          uuid !== ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks &&
          uuid !== ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllPortfolios,
      )

      if (!accessToken)
        throw new Error('Error fetching portfolio accounts: No token provided.')

      const transactionsData: GetTransactionsRESTAPIResponseType =
        await getMyStocksTransactionsApi({
          token: accessToken,
          portfolioUuids: cleanedPortfolioUuids,
          instrumentIds,
          type,
          dateFrom,
          dateTo,
          sort,
          excludePlaceholder: true,
          page,
          dispatch: dispatch as AppDispatch,
        })

      if (transactionsData && transactionsData.results) {
        const transformedTransactions = transformTransactions(
          transactionsData.results,
        )

        // Check if there are more than one transactions
        const numTransactions = transformedTransactions.filter(
          (transaction) => {
            return transaction.transactionType !== 'placeholder'
          },
        )

        dispatch(
          updateTransactionsLookup({
            currentPage: page || 1,
            hasTransactions: numTransactions.length > 0,
            loadMoreTransactions: loadMoreTransactions || false,
            transactionUuid: portfolioUuids
              ? portfolioUuids[0]
              : ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks,
            transactionsLookup: transformedTransactions,
            transactionsTotalPages: transactionsData.num_pages,
          }),
        )
        dispatch(updateFetchMoreTransactions(false))
      } else {
        // TODO: handle this better https://fool.atlassian.net/browse/PT-4922
        return false
      }
    } catch (error) {
      // TODO: handle this better https://fool.atlassian.net/browse/PT-4922
      return false
    }
    return true
  },
)

export const addPurchaseTransactions = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/addPurchaseTransactions`,
  async (
    {
      transactions,
      portfolioUuids,
      portfolioRouter,
    }: {
      transactions: CreateTransactionPayload[]
      portfolioUuids?: string[]
      portfolioRouter?: string
    },
    {dispatch, getState, extra, rejectWithValue},
  ) => {
    try {
      const {apolloClient} = extra as {apolloClient: ApolloClient<object>}

      const purchaseTransactions = await apolloClient.mutate({
        mutation: CREATE_TRANSACTION_MUTATION,
        variables: {input: transactions},
      })

      if (!purchaseTransactions) {
        throw new Error('Failed to create purchase transactions')
      }

      // If the portfolioRouter is present (meaning a user is viewing a
      // particular portfolio), we need to fetch the transactions for
      // the portfolioUuids. If not, we need to fetch all
      const portfolioUuidsForTransactions = portfolioRouter
        ? portfolioUuids
        : ['all-stocks']

      dispatch(
        getHoldings({
          portfolioUuid: portfolioRouter,
          apolloClient,
        }),
      )
      dispatch(
        getMyStocksTransactions({
          portfolioUuids: portfolioUuidsForTransactions,
        }),
      )
      dispatch(
        getMissingTransactions({
          allPortfolios: (getState() as RootState).myStocks.portfolios || [],
          type: 'single',
        }),
      )

      return true
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  },
)

export const updateMyStocksTransactions = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/updateMyStocksTransactions`,
  async (
    {
      accountId,
      transactions,
    }: {
      accountId: number
      transactions: UpdatedTransaction[]
    },
    {dispatch, getState, extra, rejectWithValue},
  ) => {
    try {
      const {apolloClient} = extra as {apolloClient: ApolloClient<object>}
      const activePortfolioUuid = (getState() as RootState).myStocks
        .activePortfolioUuid
      const transactionSets = transactions.reduce(
        (acc: TransactionSet, transaction: UpdatedTransaction) => {
          if ((transaction as NewTicker).temporaryId) {
            acc.newTransactions.push(transaction as NewTicker)
          } else if (transaction.deleteTransaction) {
            acc.deletingTransactions.push(transaction as Ticker)
          } else {
            acc.existingTransactions.push(transaction as Ticker)
          }
          return acc
        },
        {
          newTransactions: [] as NewTicker[],
          existingTransactions: [] as Ticker[],
          deletingTransactions: [] as Ticker[],
        },
      )

      if (transactionSets.newTransactions.length > 0) {
        const transformedNewTransactions = transactionSets.newTransactions.map(
          (transaction) => {
            return {
              accountId,
              instrumentId: transaction.instrumentId,
              transactionType: transaction.transactionType as TransactionType,
              shares: transaction.shares as number,
              price: transaction.price as number,
              transactionDate: transaction.transactionDate as string,
            }
          },
        )
        const createTransactionsData = await apolloClient.mutate({
          mutation: CREATE_TRANSACTION_MUTATION,
          variables: {input: transformedNewTransactions},
        })
        if (!createTransactionsData) {
          throw new Error('Failed to create new transactions')
        }
      }

      if (transactionSets.existingTransactions.length > 0) {
        const transformedUpdatedTransactions =
          transactionSets.existingTransactions.map((transaction) => {
            return {
              transactionId: (transaction as unknown as Transaction).id,
              accountId,
              instrumentId: transaction.instrumentId,
              transactionType: transaction.transactionType as TransactionType,
              shares: transaction.shares as number,
              price: transaction.price as number,
              transactionDate: transaction.transactionDate as string,
            }
          })
        for (const transaction of transformedUpdatedTransactions) {
          const updateTransactionsData = await apolloClient.mutate({
            mutation: UPDATE_TRANSACTION_MUTATION,
            variables: {input: transaction},
          })
          // TODO: Handle situation where one transaction update goes wrong https://fool.atlassian.net/browse/PT-4922
          if (!updateTransactionsData) {
            throw new Error(
              `Failed to update transaction ${transaction.transactionId}`,
            )
          }
        }
      }

      if (transactionSets.deletingTransactions.length > 0) {
        const transformedDeletedTransactions =
          transactionSets.deletingTransactions.map((transaction) => {
            return (transaction as unknown as Transaction).id
          })
        const deleteTransactionsData = await apolloClient.mutate({
          mutation: DELETE_TRANSACTION_MUTATION,
          variables: {input: {ids: transformedDeletedTransactions}},
        })
        if (!deleteTransactionsData) {
          throw new Error('Failed to delete transactions')
        }
      }

      if (activePortfolioUuid)
        dispatch(invalidateBothHoldings([activePortfolioUuid]))

      return true
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const deleteHoldingFromPortfolios = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/deleteHoldingFromPortfolios`,
  async (
    {
      token,
      portfolioUuids,
      instrumentId,
    }: {
      token?: string
      portfolioUuids?: string[]
      instrumentId: number
    },
    {dispatch, getState, extra},
  ): Promise<boolean> => {
    const {apolloClient} = extra as {apolloClient: ApolloClient<object>}
    const {session} = extra as {session: Session | null}
    const accessToken = token || session?.accessToken
    if (!accessToken)
      throw new Error('Error fetching portfolio accounts: No token provided.')
    const activePortfolioUuid = (getState() as RootState).myStocks
      .activePortfolioUuid
    let success = true
    // First fetch all the relevant transactions
    const transactionsData: GetTransactionsRESTAPIResponseType =
      await getMyStocksTransactionsApi({
        token: accessToken,
        portfolioUuids,
        instrumentIds: [instrumentId],
        dispatch: dispatch as AppDispatch,
      })

    if (!transactionsData)
      throw new Error('There was an error fetching transactions to delete')

    const transactionIds = transactionsData.results?.map(
      (transaction) => transaction.id,
    )

    const deleteTransactionsResponse = await apolloClient.mutate({
      mutation: DELETE_TRANSACTION_MUTATION,
      variables: {input: {ids: transactionIds}},
    })

    if (!deleteTransactionsResponse) {
      success = false
    }

    if (success) {
      await dispatch(
        invalidateBothHoldings([
          activePortfolioUuid || ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks,
        ]),
      )

      dispatch(
        getMyStocksTransactions({
          token,
          portfolioUuids,
        }),
      )

      if ((getState() as RootState).myStocks?.portfolios !== null) {
        const portfolios = (getState() as RootState).myStocks?.portfolios
        const activePortfolio = portfolios?.find(
          (portfolio) => portfolio?.uuid === activePortfolioUuid,
        )
        dispatch(
          getMissingTransactions({
            token,
            allPortfolios: activePortfolio ? [activePortfolio] : [],
            type: 'single',
          }),
        )
      }
    }
    // TODO: Handle when this is not a success https://fool.atlassian.net/browse/PT-4922

    return success
  },
)

export const getMissingTransactions = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/getMissingTransactions`,
  async (
    {
      token,
      allPortfolios,
      type,
    }: {
      token?: string
      allPortfolios: Portfolio[]
      type?: MissingTransactionPortfolioType
    },
    {dispatch, extra},
  ): Promise<boolean> => {
    const {session} = extra as {session: Session | null}
    const accessToken = token || session?.accessToken
    if (!accessToken)
      throw new Error('Error fetching portfolio accounts: No token provided.')
    let success = false
    try {
      let allStocksMissingTransactions =
        await getMyStocksPlaceholderHoldingsApi(accessToken)
      allStocksMissingTransactions = transformMissingTransactions(
        allStocksMissingTransactions,
      )
      const allMissingTransactionsWithPortfolio =
        allStocksMissingTransactions.map((transaction: MissingTransactions) => {
          const portfolio = allPortfolios?.find(
            (portfolio) => portfolio?.uuid === transaction?.accountUuid,
          )
          const portfolioName = portfolio?.name || ''
          return {...transaction, portfolio: portfolioName}
        })
      allMissingTransactionsWithPortfolio.sort(
        (a: MissingTransactions, b: MissingTransactions) => {
          if (a !== undefined && b !== undefined && a !== null && b !== null) {
            if (a.portfolio && b.portfolio) {
              if (a.portfolio < b.portfolio) {
                return -1
              } else if (a.portfolio > b.portfolio) {
                return 1
              }
            }
          }
        },
      )

      if (type === 'single') {
        dispatch(
          updateMissingPortfolioTransactions({
            portfolioMissingTransactions: allMissingTransactionsWithPortfolio,
          }),
        )
      }

      // Any time getMissingTransactions is called, we need to update the state of ALL missing transactions since some type of change has happened (edit or add)
      dispatch(
        updateAllMissingTransactions({
          portfolioMissingTransactions: allMissingTransactionsWithPortfolio,
        }),
      )
      if (allMissingTransactionsWithPortfolio) {
        success = true
      }
    } catch (error) {
      console.log(error)
    }
    return success
  },
)

export const deleteMyStocksTransaction = createAsyncThunk(
  `${MY_STOCKS_REDUCER_KEY}/deleteMyStocksTransaction`,
  async (
    {
      token,
      transactionId,
    }: {
      token?: string
      transactionId: number
    },
    {dispatch, getState, extra},
  ): Promise<boolean> => {
    const {apolloClient} = extra as {apolloClient: ApolloClient<object>}
    try {
      const deletedTransactionData = await apolloClient.mutate({
        mutation: DELETE_TRANSACTION_MUTATION,
        variables: {input: {ids: [transactionId]}},
      })
      if (deletedTransactionData) {
        const portfolio = (getState() as RootState).myStocks.activePortfolioUuid
        if (portfolio) {
          dispatch(
            getMyStocksTransactions({
              token,
              portfolioUuids: [portfolio],
            }),
          )
          // When you delete a transaction, the holdings should be updated.
          // This ensures that if the user clicks the Stocks tab, it has removed the transaction from that view
          dispatch(
            getHoldings({
              portfolioUuid: portfolio,
              apolloClient: apolloClient,
            }),
          )
        }
      } else {
        throw new Error('There was an error deleting a transaction')
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error, {
        extra: {
          context: 'deleteMyStocksTransaction',
        },
      })
      throw error
    }
    return true
  },
)
