import {ApolloClient} from '@apollo/client'
import type {
  Holding,
  WatchlistHolding,
  getAccountsRESTAPIResponseType,
  sanitizdGetHoldingsRESTAPIResponseType,
} from '~types/my-stocks'
import type {
  HoldingRESTAPIResponseType,
  Portfolio,
  PortfolioRESTAPIResponseType,
  TransactionRESTAPIResponseType,
  Transaction,
  MissingTransactionsResponseType,
  MissingTransactions,
  Quote,
} from '~types/my-stocks'
import {sanitizeProperties} from '~data/api/utils'
import {getQuotesData, getInstrumentGQLData} from '~data/api/instruments'
import {createInstrumentIdBatch} from '~utils/batch'
import {
  MyStocksHoldingsQuery,
  MyStocksWatchlistQuadrantQuery,
} from '~types/__generated__/graphql'

type TempSanitizedTransaction = {
  id: number
  instrumentId: number
  portfolioName: string
  accountName: string
  institutionLogo: string | null
  accountUuid: string
  company: string
  symbol: string
  date: string
  type: string
  shares: string
  price: string
  amount: string
  currencyCode: string
  groupingName: string
}

const transformPortfolios = (
  portfolios: PortfolioRESTAPIResponseType[],
  summaryData: PortfolioRESTAPIResponseType[],
): Portfolio[] => {
  const sanitizedSummaryPortfolios = sanitizeProperties(
    summaryData,
  ) as Portfolio[]

  const summaryPortfoliosMap = sanitizedSummaryPortfolios.reduce(
    (acc, portfolio) => {
      if (portfolio?.uuid) {
        acc[portfolio.uuid] = portfolio
      }
      return acc
    },
    {} as {[key: string]: Portfolio},
  )
  // Sanitize the incoming portfolios
  const portfoliosSanitized = sanitizeProperties(portfolios) as Portfolio[]
  const combinedPortfolio = portfoliosSanitized.map((portfolio) => {
    if (!portfolio) {
      return null // or handle the case however you need to
    }
    const summaryPortfolio = portfolio.uuid
      ? summaryPortfoliosMap[portfolio.uuid]
      : null

    // Merge the portfolio data with the summary portfolio data if available
    return {
      ...portfolio,
      id: portfolio?.id ?? 0,
      uuid: portfolio?.uuid ?? '',
      name: portfolio?.name ?? '',
      cash: portfolio?.cash ?? 0,
      balance: portfolio?.balance ?? 0,
      numSecurities: portfolio?.numSecurities ?? 0,
      notional: portfolio?.notional ?? false,
      default: portfolio?.default ?? false,
      hasTransactions: portfolio?.hasTransactions ?? false,
      isoCurrencyCode: portfolio?.isoCurrencyCode ?? '',
      created: portfolio?.created ?? '',
      type: portfolio?.type ?? '',
      percentGainOrLoss: summaryPortfolio?.percentGainOrLoss
        ? summaryPortfolio.percentGainOrLoss * 100
        : 0,
      todayPercentGainOrLoss: summaryPortfolio?.todayPercentGainOrLoss
        ? summaryPortfolio?.todayPercentGainOrLoss * 100
        : 0,
    }
  })

  return combinedPortfolio
}

const transformMissingTransactions = (
  transactions: MissingTransactionsResponseType[],
): MissingTransactions[] => {
  return sanitizeProperties(transactions) as MissingTransactions[]
}

const transformHoldings = async (
  holdings: HoldingRESTAPIResponseType[],
  apolloClient?: ApolloClient<object>,
): Promise<{
  transformedHoldings: Holding[]
  successfulQuotesAndGraphQLPull: boolean
}> => {
  // TODO: why do we have this async function inside another async function?
  const data = async (): Promise<{
    transformedHoldings: Holding[]
    successfulQuotesAndGraphQLPull: boolean
  }> => {
    let quotesIsSuccess = false
    let graphQLIsSuccess = false
    const sanitizedHoldings = sanitizeProperties(holdings) as Holding[]
    const instrumentIds = sanitizedHoldings.map(
      (instrument) => instrument.instrumentId,
    )

    const batches: string[] | [] =
      instrumentIds && instrumentIds.length
        ? createInstrumentIdBatch(instrumentIds)
        : []

    let transformedHoldings = [] as Holding[]
    if (batches?.length) {
      // Fetch quotes
      try {
        const quotes = await getQuotesData(batches)
        if (quotes) quotesIsSuccess = true
        transformedHoldings = [...sanitizedHoldings].map((holding) => {
          let transformedHolding: Holding = {
            id: holding.id,
            instrumentId: holding.instrumentId,
            name: holding.name,
            symbol: holding.symbol,
            costPerShare: 0,
            percentChange: null,
            priceChange: null,
            currencyCode: '',
            percentOfPortfolio: (holding.percentOfPortfolio || 0) * 100,
            totalChange: (holding.totalChange || 0) * 100,
            shares: holding.shares,
            cost: holding.cost,
            value: holding.value,
            // holding.portfolios returns null when the holdings retrieved
            // are all for the currently active portfolio
            portfolios: holding.portfolios,
          }

          if (quotes) {
            const quoteObj: Quote = holding.instrumentId
              ? (quotes as Record<string, Quote>)[
                  holding.instrumentId.toString()
                ] || {}
              : {}
            transformedHolding = {
              ...transformedHolding,
              exchange: quoteObj?.exchange || null,
              costPerShare: quoteObj?.price || 0,
              currencyCode: quoteObj?.code || '',
              percentChange: quoteObj?.change,
              priceChange: quoteObj?.priceChange,
              beta5Y: quoteObj?.beta5Y,
              fiftyTwoWeekRange: quoteObj?.fiftyTwoWeekRange,
              marketCap: quoteObj?.marketCap,
              quotesTimestamp: quoteObj?.timestamp,
            }
          }
          return transformedHolding
        })
      } catch (e) {
        // TODO: Handle this better https://fool.atlassian.net/browse/PT-4922
        console.log(e)
      }
    }

    // Get other instrument data through graphql
    if (apolloClient) {
      const dataFromGraphQL = await getInstrumentGQLData(
        transformedHoldings,
        apolloClient,
      )
      if (dataFromGraphQL) {
        graphQLIsSuccess = true
        transformedHoldings = dataFromGraphQL
      }
    }

    return {
      transformedHoldings,
      successfulQuotesAndGraphQLPull: quotesIsSuccess && graphQLIsSuccess,
    }
  }
  const holdingsData = await data()
  return holdingsData
}

const transformTransactions = (
  transactions: TransactionRESTAPIResponseType[],
): Transaction[] => {
  const sanitizedTransactions: TempSanitizedTransaction[] = sanitizeProperties(
    transactions,
  ) as TempSanitizedTransaction[]
  const transformedTransactions: Transaction[] = sanitizedTransactions.map(
    (transaction: TempSanitizedTransaction) => {
      return {
        id: transaction.id,
        instrumentId: transaction.instrumentId,
        portfolioName: transaction.portfolioName,
        accountName: transaction.accountName,
        institutionLogo: transaction.institutionLogo,
        accountUuid: transaction.accountUuid,
        company: transaction.company,
        symbol: transaction.symbol,
        currencyCode: transaction.currencyCode,
        groupingName: transaction.groupingName,
        transactionDate: transaction.date,
        transactionType: transaction.type,
        shares: parseFloat(transaction.shares),
        price: parseFloat(transaction.price),
        amount: parseFloat(transaction.amount),
      }
    },
  ) as Transaction[]
  return transformedTransactions
}

// Overall Profit & Loss Percentage is calculated by
// dividing profit loss to cost
const transformHoldingsOverallProfitLoss = (
  holdingData: sanitizdGetHoldingsRESTAPIResponseType,
) => {
  return holdingData.profitAndLoss / holdingData.cost || 0
}

//The UUID for Plaid Portfolios is in the accounts property and not the root UUID in the response
const transformPlaidPortfolios = (
  portfolioData: getAccountsRESTAPIResponseType,
) => {
  const filteredData = portfolioData.portfolios
    .filter(
      (portfolio: PortfolioRESTAPIResponseType) => portfolio?.type === 'plaid',
    )
    .map((item) => {
      return {...item, uuid: item.accounts[0]?.uuid}
    })
  return filteredData
}

type GraphQLHoldings = NonNullable<
  NonNullable<
    NonNullable<MyStocksHoldingsQuery['myStocksHoldings']>['holdings']
  >
>
export type GraphQLHolding = GraphQLHoldings extends (infer T)[] ? T : never

const transformHoldingsNew = (holdings: GraphQLHoldings): Holding[] => {
  const transformedHoldings = holdings.reduce(
    (acc: Holding[], holding: GraphQLHolding) => {
      if (holding) {
        acc.push({
          id: holding.id,
          instrumentId: holding.instrumentId,
          exchange: holding.instrument?.exchange,
          name: holding.name,
          symbol: holding.symbol,
          portfolios: holding.portfolios,
          percentOfPortfolio: holding.percentOfPortfolio,
          currencyCode: holding.currencyCode,
          priceChange: holding.instrument?.quote?.priceChange?.amount,
          percentChange: holding.instrument?.quote?.percentChange
            ? Math.round(
                (holding.instrument?.quote?.percentChange || 0) * 100 * 100,
              ) / 100
            : null,
          shares: holding.shares,
          costPerShare: holding.costPerShare,
          cost: holding.cost,
          currentPrice: holding.instrument?.quote?.currentPrice?.amount,
          closingPrice: holding.closingPrice,
          value: holding.value,
          lastCloseValue: holding.lastCloseValue,
          totalChange: holding.gainOrLoss,
          totalChangePercent: (holding.percentGainOrLoss || 0) * 100,
          todayChange: holding.todayGainOrLoss,
          todayChangePercent: holding.todayPercentGainOrLoss,
          isCash: holding.isCash,
          beta5Y: holding.instrument?.quote?.beta5y,
          fiftyTwoWeekRange:
            holding.instrument?.quote?.fiftyTwoWeekRange?.min.amount,
          marketCap: holding.instrument?.quote?.marketCap?.amount,
          numOfRecs:
            holding.instrument?.accessibleFoolRecommendations?.length || 0,
          quotesTimestamp: new Date().getTime(),
          revenueGrowth: holding.instrument?.quote?.revenueGrowth,
          projectedReturns: holding.instrument?.predictionByInstrument
            ? [
                holding.instrument?.predictionByInstrument.returnEstimateLow,
                holding.instrument?.predictionByInstrument.returnEstimateHigh,
              ]
            : null,
          estimatedDrawdown:
            holding.instrument?.predictionByInstrument?.maxDrawdownRiskPerYear,
          cmaTagLabel: holding.instrument?.predictionByInstrument?.cmaTagLabel,
          dividendYield: holding.instrument?.quote?.dividendYield,
          peRatio: holding.instrument?.quote?.peRatio,
          sector: holding.instrument?.sector,
          grossMargin: holding.instrument?.quote?.grossMargin,
          quant5y: holding.instrument?.quantScore?.value,
          totalRecs: holding.instrument?.accessibleFoolRecommendations,
        })
      }
      return acc
    },
    [],
  )
  return transformedHoldings
}

type GraphQLWatchlistHoldings = NonNullable<
  NonNullable<MyStocksWatchlistQuadrantQuery['myStocksWatchlist']>
>

export type GraphQLWatchlistHolding =
  GraphQLWatchlistHoldings extends (infer T)[] ? T : never

const transformWatchlistHoldings = (
  holdings: GraphQLWatchlistHoldings,
): WatchlistHolding[] => {
  return holdings.reduce(
    (acc: WatchlistHolding[], holding: GraphQLWatchlistHolding) => {
      if (holding) {
        acc.push({
          id: holding?.id,
          instrumentId: holding?.instrumentId,
          name: holding?.name,
          symbol: holding?.symbol,
          exchange: holding?.instrument?.quote?.exchange,
          currentPrice: holding?.instrument?.quote?.currentPrice
            ? parseFloat(holding?.instrument?.quote?.currentPrice)
            : null,
          priceChange: holding?.instrument?.quote?.priceChange
            ? parseFloat(holding?.instrument?.quote?.priceChange)
            : null,
          percentChange: holding?.instrument?.quote?.percentChange
            ? Math.round(
                (holding?.instrument?.quote?.percentChange || 0) * 100 * 100,
              ) / 100
            : null,
          portfolios: holding?.portfolios,
        })
      }
      return acc
    },
    [],
  )
}

export {
  transformPortfolios,
  transformHoldings,
  transformHoldingsNew,
  transformTransactions,
  transformHoldingsOverallProfitLoss,
  transformMissingTransactions,
  transformPlaidPortfolios,
  transformWatchlistHoldings,
}
